import React, { Component } from "react";
import { Anchor, Heading, Paragraph } from "grommet";
import Section from "../components/Section";
import Page from "../components/Page";

class Terms extends Component {
  render() {
    return (
      <Page title="Bedingungen">
        <Section background="white">
          <Paragraph fill>
            Mit den Aufruf dieser Website unter{" "}
            <Anchor href="https://schloss-ehrenfels.org">
              Schloss-Ehrenfels.org
            </Anchor>{" "}
            erklären Sie sich damit einverstanden, an diese Nutzungsbedingungen,
            alle anwendbaren Gesetze und Vorschriften gebunden zu sein und
            erklären sich damit einverstanden, dass Sie für die Einhaltung der
            geltenden Gesetze verantwortlich sind. Wenn Sie mit diesen
            Bedingungen nicht einverstanden sind, ist es verboten, auf diese
            Website zuzugreifen.
          </Paragraph>
          <Heading level={3}>Nutzungsrecht</Heading>
          <Paragraph fill>
            Soweit nicht anders vermerkt, ist der Inhalt auf
            Schloss-Ehrenfels.org unter einer{" "}
            <Anchor
              href="http://creativecommons.org/licenses/by/4.0/"
              target="_blank"
            >
              Creative Commons Attribution 4.0 International Lizenz
            </Anchor>{" "}
            lizenziert.
          </Paragraph>
          <Heading level={3}>Haftungsausschluss</Heading>
          <Paragraph fill>
            Die Informationen auf Schloss-Ehrenfels.org werden auf einer
            &quot;wie gesehen&quot; Basis zur Verfügung gestellt.
            Schloss-Ehrenfels.org übernimmt keinerlei Haftung, weder
            ausdrücklich noch stillschweigend, und lehnt jede andere
            Gewährleistung ab, einschließlich, und ohne Einschränkung,
            stillschweigende Garantien der Marktgängigkeit, Eignung für einen
            bestimmten Zweck, oder Nichtverletzung von geistigem Eigentum, oder
            sonstiger Verletzung von Rechten.
          </Paragraph>
          <Paragraph fill>
            Weiterhin garantiert Schloss-Ehrenfels.org keine Zusicherungen
            hinsichtlich der Richtigkeit, oder der Zuverlässigkeit der
            verwendeten Materialien auf unserer Website oder Websites, die mit
            dieser verbunden sind.
          </Paragraph>
          <Heading level={3}>Einschränkungen</Heading>
          <Paragraph fill>
            In keinem Fall haftet Schloss-Ehrenfels.org oder dessen Zulieferer
            für Schäden (einschließlich, und ohne Einschränkung, Schadensersatz
            wegen Verlust von Daten oder Gewinn, oder aufgrund von
            Betriebsunterbrechungen), die sich aus der Nutzung oder der
            Nichtnutzung der Materialien auf Schloss-Ehrenfels.org ergeben, auch
            wenn Schloss-Ehrenfels.org oder ein bevollmächtigter Vertreter von
            Schloss-Ehrenfels.org mündlich oder schriftlich über die Möglichkeit
            eines solchen Schadens informiert wurde. Weil einige
            Gerichtsbarkeiten keine Einschränkungen bei stillschweigenden
            Garantien oder Haftungsbeschränkungen für Folgeschäden oder
            zufällige Schäden zulassen, könnten diese Einschränkungen für Sie
            nicht zutreffen.
          </Paragraph>
          <Heading level={3}>Richtigkeit der Informationen</Heading>
          <Paragraph fill>
            Die auf Schloss-Ehrenfels.org veröffentlichen Informationen könnten
            technische, typografische oder visuelle Fehler enthalten.
            Schloss-Ehrenfels.org garantiert nicht, dass die Informationen auf
            ihrer Website korrekt, vollständig oder aktuell sind.
            Schloss-Ehrenfels.org kann jederzeit Änderungen an den auf seiner
            Website enthaltenen Informationen vornehmen. Schloss-Ehrenfels.org
            verpflichtet sich nicht, die Materialien zu aktualisieren.
          </Paragraph>
          <Heading level={3}>Verlinkungen</Heading>
          <Paragraph fill>
            Schloss-Ehrenfels.org hat nicht alle mit der Website verknüpften
            Seiten überprüft und ist für den Inhalt einer solchen verlinkten
            Seite nicht verantwortlich. Die Einbeziehung eines Links bedeutet
            nicht die Anerkennung von Schloss-Ehrenfels.org der Website. Die
            Nutzung einer solchen verlinkten Website erfolgt auf eigene Gefahr.
          </Paragraph>
          <Heading level={3}>Änderungen</Heading>
          <Paragraph fill>
            Schloss-Ehrenfels.org kann diese Nutzungsbedingungen jederzeit ohne
            vorherige Ankündigung für seine Website überarbeiten. Durch die
            Nutzung dieser Website erklären Sie sich einverstanden, an die
            aktualisierte Version dieser Nutzungsbedingungen gebunden zu sein.
          </Paragraph>
        </Section>
      </Page>
    );
  }
}

export default Terms;
