import React, { Component } from "react";
import { Grommet, ResponsiveContext } from "grommet";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// routes
import Blog from "./screens/Blog";
import Contact from "./screens/Contact";
import Directions from "./screens/Directions";
import Home from "./screens/Home";
import Terms from "./screens/Terms";
import Privacy from "./screens/Privacy";
import Legal from "./screens/Legal";
import Information from "./screens/Information";
import { theme } from "./util";

function Main(size) {
  return (
    <Router>
      <Switch>
        <Route path="/about" component={Home} />
        <Route path="/blog" component={Blog} />
        <Route path="/contact" component={Contact} />
        <Route path="/directions" component={Directions} />
        <Route path="/information" component={Information} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/legal" component={Legal} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

class App extends Component {
  render() {
    return (
      <Grommet theme={theme}>
        <ResponsiveContext.Consumer>{Main}</ResponsiveContext.Consumer>
      </Grommet>
    );
  }
}

export default App;
