import React, { Component } from "react";
import {
  Anchor,
  Button,
  Box,
  Form,
  FormField,
  Heading,
  Text,
  TextArea,
  TextInput,
  Paragraph
} from "grommet";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: {
        value: "",
        error: "Pflichtfeld"
      },
      lastName: {
        value: "",
        error: "Pflichtfeld"
      },
      email: {
        value: "",
        error: "Pflichtfeld"
      },
      message: {
        value: "",
        help: "800 Buchstaben verbleibend",
        error: "Pflichtfeld"
      },
      sent: false,
      sentSuccess: false,
      showErrors: false
    };
  }

  onFirstNameChange = e => {
    const { value } = e.target;
    const error = value.length > 0 ? null : "Pflichtfeld";
    this.setState({
      firstName: {
        value,
        error
      }
    });
  };

  onLastNameChange = e => {
    const { value } = e.target;
    const error = value.length > 0 ? null : "Pflichtfeld";
    this.setState({
      lastName: {
        value,
        error
      }
    });
  };

  onEmailChange = e => {
    const { value } = e.target;
    const error = value.length > 0 ? null : "Pflichtfeld";
    this.setState({
      email: {
        value,
        error
      }
    });
  };

  onMessageChange = e => {
    const value = (e.target.value || "").substr(0, 800);
    const error = value.length > 0 ? null : "Pflichtfeld";
    const remainingCharacters = 800 - value.length;

    const help =
      remainingCharacters === 1
        ? `${remainingCharacters} Buchstabe verbleibend`
        : `${remainingCharacters} Buchstaben verbleibend`;

    this.setState({
      message: {
        value,
        help,
        error
      }
    });
  };

  onReset = () => {
    this.setState({
      firstName: {
        value: "",
        error: "Pflichtfeld"
      },
      lastName: {
        value: "",
        error: "Pflichtfeld"
      },
      email: {
        value: "",
        error: "Pflichtfeld"
      },
      message: {
        value: "",
        help: "800 Buchstaben verbleibend",
        error: "Pflichtfeld"
      },
      sent: false,
      sentSuccess: false,
      showErrors: false
    });
  };

  onSubmit = () => {
    // check for any form errors
    const { firstName, lastName, email, message } = this.state;
    if (firstName.error || lastName.error || email.error || message.error) {
      this.setState({
        showErrors: true
      });
      return;
    }

    // prepare the AJAX request
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: `${firstName.value} ${lastName.value}`,
        email: email.value,
        message: message.value
      })
    };

    // send the request
    const that = this;
    fetch("/request.php", options)
      .then(data => {
        if (data.ok) {
          that.setState({
            sent: true,
            sentSuccess: true
          });
        } else {
          that.setState({
            sent: true,
            sentSuccess: false
          });
        }
      })
      .catch(() => {
        that.setState({
          sent: true,
          sendSuccess: false
        });
      });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      message,
      sent,
      sentSuccess,
      showErrors
    } = this.state;

    if (sent && sentSuccess) {
      return (
        <Box
          background="status-ok"
          pad="medium"
          round="small"
          animation="fadeIn"
        >
          <Heading level={3} margin="none">
            Erfolg
          </Heading>
          <Text>
            Vielen Dank f&uuml;r Ihre Nachricht. Wir werden uns in k&uuml;rze
            mit Ihnen in Verbindung setzen.
          </Text>
        </Box>
      );
    }

    if (sent && !sentSuccess) {
      return (
        <Box
          background="status-error"
          pad="medium"
          round="small"
          animation="fadeIn"
        >
          <Heading level={3} margin="none">
            Fehler
          </Heading>
          <Text>
            Ihre Nachricht kann derzeit nicht verarbeitet werden. Bitte
            versuchen Sie es zu einem sp&auml;teren Zeitpunkt erneut oder senden
            Sie eine Email an info@schloss-ehrenfels.org.
          </Text>
        </Box>
      );
    }

    return (
      <Form>
        <Box background="light-1" pad="medium" round="small">
          <Paragraph fill size="small">
            Bitte wenden Sie sich f&uuml;r Fragen bezgl. Hochzeiten und andere
            Feiern im Schloss Ehrenfels direkt and den aktuellen P&auml;chter{" "}
            <Anchor href="https://www.tress-gastronomie.de/tagen-und-feiern/schloss-ehrenfels">
              Tress Gastronomie
            </Anchor>
            . Vielen Dank.
          </Paragraph>
        </Box>
        <Box pad={{ vertical: "medium" }}>
          <FormField
            label="Vorname"
            htmlFor="firstName"
            error={showErrors ? firstName.error : ""}
          >
            <TextInput
              name="firstName"
              id="firstName"
              value={firstName.value}
              onChange={this.onFirstNameChange}
            />
          </FormField>
          <FormField
            label="Nachname"
            htmlFor="lastName"
            error={showErrors ? lastName.error : ""}
          >
            <TextInput
              name="lastName"
              id="lastName"
              value={lastName.value}
              onChange={this.onLastNameChange}
            />
          </FormField>
          <FormField
            label="Email"
            htmlFor="email"
            error={showErrors ? email.error : ""}
          >
            <TextInput
              name="email"
              id="email"
              value={email.value}
              onChange={this.onEmailChange}
            />
          </FormField>
          <FormField
            label="Nachricht"
            htmlFor="message"
            help={message.help}
            error={showErrors ? message.error : ""}
          >
            <TextArea
              name="message"
              id="message"
              value={message.value}
              onChange={this.onMessageChange}
              rows="5"
              resize="vertical"
            />
          </FormField>
        </Box>
        <Box pad="medium" gap="medium" direction="row">
          <Button
            label="Formular zur&uuml;cksetzen"
            onClick={this.onReset}
            plain
          />
          <Button label="Senden" onClick={this.onSubmit} primary />
        </Box>
      </Form>
    );
  }
}

export default ContactForm;
