import { base } from "grommet";

export const theme = {
  global: {
    colors: {
      brand: "#228BE6",
      "neutral-1": "#C2C6C0",
      "neutral-2": "#36514E",
      "neutral-3": "#B98E62",
      "neutral-4": "#B05C42"
    },
    font: {
      family: "'Open Sans', Arial, sans-serif",
      weight: "300",
      size: "14px",
      height: "20px"
    }
  },
  heading: {
    weight: "100"
  },
  anchor: {
    color: {
      dark: "neutral-3",
      light: "neutral-3"
    }
  }
};

export function getGradient(src) {
  const result = {
    color: src,
    dark: true,
    opacity: false
  };

  // lookup theme color
  let c = src;
  if (src.indexOf("#") !== 0 && src.indexOf("url") !== 0) {
    if (base.global.colors.hasOwnProperty(src)) {
      c = base.global.colors[src];
    }
    if (theme.global.colors.hasOwnProperty(src)) {
      c = theme.global.colors[src];
    }
  }

  if (c.indexOf("#") === 0) {
    const b1 = c.substr(1, 2);
    const b2 = c.substr(3, 2);
    const b3 = c.substr(5, 2);

    const s1 = parseInt(b1, 16);
    const s2 = parseInt(b2, 16);
    const s3 = parseInt(b3, 16);

    let factor = 7;
    if (s1 > 192 || s2 > 192 || s3 > 192) {
      result.dark = false;
      factor = -factor;
    }

    const n1 = Math.max(0, Math.min(s1 + factor, 255));
    const n2 = Math.max(0, Math.min(s2 + factor, 255));
    const n3 = Math.max(0, Math.min(s3 + factor, 255));

    const h1 = n1.toString(16).padStart(2, "0");
    const h2 = n2.toString(16).padStart(2, "0");
    const h3 = n3.toString(16).padStart(2, "0");

    const h = `#${h1}${h2}${h3}`;
    const bg = `repeating-linear-gradient(135deg, ${h}, ${h} 2px, ${c} 2px, ${c} 10px)`;

    result.image = bg;
    return result;
  }

  return c;
}

export default {
  getGradient: getGradient
};
