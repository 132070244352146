import React, { Component } from "react";
import { Heading, Paragraph } from "grommet";
import Section from "../components/Section";
import Page from "../components/Page";

class Privacy extends Component {
  render() {
    return (
      <Page title="Datenschutzerklärung">
        <Section background="white">
          <Paragraph fill>
            Ihre Privatsphäre ist uns wichtig. Wir sind engagiert, Ihre
            Privatsphäre bei der Verarbeitung personenbezogener Daten zu
            respektieren. Wir verarbeiten personenbezogene Daten, die beim
            Besuch unserer Webseiten erhoben werden, gemäß den gesetzlichen
            Bestimmungen. Unsere Website kann Links zu den Seiten anderer
            Anbieter enthalten, die nicht unter diese Datenschutzerklärung
            fallen. Schloss-Ehrenfels.org kann diese Datenschutzerklärung von
            Zeit zu Zeit nach eigenem Ermessen ändern.
          </Paragraph>
          <Heading level={3}>Erhebung personenbezogener Daten</Heading>
          <Paragraph fill>
            Es werden keine personalisierten Protokolle über Besucher von
            Schloss-Ehrenfels.org gespeichert. Es werden jedoch Daten
            aufgezeichnet und ausgetauscht, wenn eine Website besucht wird.
            Schloss-Ehrenfels.org speichert Daten, die Ihr Browser an unseren
            Server sendet. Dies schließt folgende Daten ein:
          </Paragraph>
          <ul>
            <li>Name und Version des verwendeten Browsers</li>
            <li>Spracheinstellungen</li>
            <li>Aufgerufene Website</li>
            <li>Zuvor besuchte Seite (referer URL)</li>
            <li>Datum und Uhrzeit des Aufrufs</li>
          </ul>
          <Paragraph fill>
            Schloss-Ehrenfels.org verwendet diese Daten für statistische Zwecke
            und um die Website zu verbessern. Daten für diese Zwecke werden
            anonym erhoben (mit verkürzten IP Addressen) und können nicht einer
            bestimmte Person zugeordnet werden. Eine Zusammenführung dieser
            Daten mit anderen Datenquellen wird nicht vorgenommen.
          </Paragraph>
          <Paragraph fill>
            Personenbezogene Daten werden ausschließlich gespeichert wenn der
            Benutzer diese zur Verfügung stellt, zum Beispiel bei der Verwendung
            des Kontaktformulars. Eine moderne Verschlüsselungsmethode (Secure
            Sockets Layer, SSL) wird verwendet um die personenbezogenen Daten
            sicher zu übertragen.
          </Paragraph>
          <Heading level={3}>Auskunftsrecht</Heading>
          <Paragraph fill>
            Sie haben jederzeit das Recht auf Auskunft über die bezüglich Ihrer
            Person gespeicherten Daten, deren Herkunft sowie den Zweck der
            Speicherung. Auf Anforderung teilen wir Ihnen entsprechend geltendem
            Recht mit, ob und welche personenbezogenen Daten über Sie bei uns
            gespeichert sind. Sollten trotz unserer Bemühungen falsche
            Informationen gespeichert sein, werden wir diese auf Ihre
            Aufforderung hin berichtigen. Auskunft über die gespeicherten Daten
            gibt Ihnen die Redaktion.
          </Paragraph>
          <Heading level={3}>
            Nutzung und Weitergabe personenbezogener Daten
          </Heading>
          <Paragraph fill>
            Wir nutzen Ihre personenbezogenen Daten nur zu den bei der Abfrage
            genannten Zwecken. Ihre Daten werden ohne Ihr ausdrückliches
            Einverständnis nicht an Dritte weitergegeben.
          </Paragraph>
        </Section>
      </Page>
    );
  }
}

export default Privacy;
