/**
 * Copyright (C) 2017, Tobias Flitsch.
 * All rights reserved.
 */

import { Component } from "react";
import { withRouter } from "react-router-dom";

function analyze(path) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      timestamp: Date.now(),
      path
    })
  };

  fetch("/ct.php", options)
    .then(() => {})
    .catch(() => {});
}

class ScrollToTop extends Component {
  componentDidMount() {
    const { location } = this.props;
    this.timer = window.setInterval(() => {
      analyze(location.pathname);
    }, 30000);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);

      // send some analytics data
      analyze(location.pathname);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      window.clearInterval(this.timer);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
