import React, { Component } from "react";
import { Paragraph } from "grommet";
import Section from "../components/Section";
import Page from "../components/Page";

class Directions extends Component {
  render() {
    const uri = [
      "https://www.google.com/maps/embed?",
      "pb=!1m18!1m12!1m3!1d2656.03525220125!2d9.448000426364278!3d48.263696130334736",
      "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s",
      "0x479a28c7197ecf9b%3A0xf23cd2e71cebd697!2sSchloss+Ehrenfels!5e0!3m2!1sen!2sus!",
      "4v1493503878916"
    ].join("");

    return (
      <Page title="Anfahrt Schloss Ehrenfels">
        <Section background="white">
          <Paragraph fill size="large">
            Besuchen Sie das Schloss Ehrenfels und Umgebung. Zahlreiche
            Wandermoeglichkeiten und Freizeiterlebnisse warten auf Sie.
          </Paragraph>
        </Section>
        <Section>
          <iframe
            title="Google Maps - Schloss Ehrenfels"
            src={uri}
            width="700"
            height="450"
            style={{ border: 0 }}
          >
            Your browser does not support iFrames.
          </iframe>
        </Section>
      </Page>
    );
  }
}

export default Directions;
