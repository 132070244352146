import React, { Component } from "react";
import { Box } from "grommet";
import { withRouter } from "react-router-dom";
import RoutedAnchor from "./RoutedAnchor";
import Logo from "./Logo";

const links = [
  {
    path: "/information",
    label: "Informationen"
  },
  {
    path: "/blog",
    label: "Aktuelles"
  },
  {
    path: "/contact",
    label: "Kontakt & Spenden"
  }
];

class Header extends Component {
  _onHomeClick = () => {
    const { history } = this.props;
    history.push("/");
  };

  render() {
    // menu items
    const menuItems = links.map(link => (
      <RoutedAnchor key={link.label} href={link.path}>
        {link.label}
      </RoutedAnchor>
    ));

    const menu = (
      <Box direction="row" gap="medium">
        {menuItems}
      </Box>
    );

    // TODO: this should be a svg graphic
    const title = (
      <Box
        direction="column"
        pad="none"
        onClick={this._onHomeClick}
        align={"center"}
      >
        <Box direction="column" pad="none" align={"center"}>
          <Logo height="xxsmall" width="xsmall" plain color="#36514E" />
        </Box>
      </Box>
    );

    return (
      <Box
        as="header"
        direction="column"
        align="center"
        pad="medium"
        background="white"
      >
        <Box direction="row" align="center" justify="between" width="large">
          {title}
          <Box margin="none" gap="small" direction="row" justify="end">
            {menu}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withRouter(Header);
