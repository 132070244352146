import React, { Component } from "react";
import { Paragraph } from "grommet";
import Section from "../components/Section";
import Page from "../components/Page";
import ContactForm from "../components/ContactForm";

class Contact extends Component {
  render() {
    return (
      <Page title="Kontakt & Spenden">
        <Section background="light-1">
          <Paragraph fill>
            Die Idee von Roland Freiherr von Saint-André († 2015) und seiner
            Tochter Nicoletta, eine Stiftung zur Erhaltung von Schloss Ehrenfels
            und der Friedrichsmühle, wurde im Jahr 2016 von Nicoletta Freifrau
            von Saint-André in die Tat umgesetzt.
          </Paragraph>
          <Paragraph fill>
            Die Stiftung, zusammen mit Ihrer Unterstützung, sorgt künftig dafür,
            dass Schloss und Wimsener Mühle erhalten bleiben. Nicoletta Freifrau
            von Saint-André leitet gemeinsam mit Johannes von Ritter Freiherr
            von Groenesteyn, Enkel des Barons, sowie Reinhold Flitsch die
            Stiftung.
          </Paragraph>
          <Paragraph fill>
            Wenn Sie Fragen zum Thema Spenden haben, z.B. auch zu aktuellen
            Projekten, beantworten wir diese gerne. Füllen Sie einfach
            nachstehendes Formular aus und senden Sie uns Ihre Fragen, Wünsche
            oder Anregungen.
          </Paragraph>
        </Section>
        <Section title="Kontaktformular" background="white">
          <ContactForm />
        </Section>
      </Page>
    );
  }
}

export default Contact;
