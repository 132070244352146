import styled, { css } from "styled-components";
import React, { Component } from "react";
import { Box } from "grommet";

const hoverStyle = props => {
  if (props.onClick) {
    return css`
      &:hover .primary {
        fill: #b98e62;
      }
    `;
  }
};

const Svg = styled.svg`
  & g {
    fill: ${props => (props.plain ? props.color : "white")};
    fill-rule: nonzero;
  }
  ${props => hoverStyle(props)}
`;

class Logo extends Component {
  render() {
    const { onClick, height, width, plain, color } = this.props;

    console.log(this.props.theme);

    return (
      <Box height={height} width={width} onClick={onClick}>
        <Svg
          plain={plain}
          color={color || "#b98e62"}
          width="100%"
          height="100%"
          viewBox="0 0 676 266"
          version="1.1"
        >
          <g className="primary">
            <path d="M60.393,88.588l-38.239,0l0,29.29l31.405,0l0,19.526l-31.405,0l0,29.29l38.239,0l0,19.526l-59.393,0l0,-117.158l59.393,0l0,19.526Z" />
            <path d="M118.159,69.062l21.153,0l0,117.158l-21.153,0l0,-48.816l-22.781,0l0,48.816l-21.154,0l0,-117.158l21.154,0l0,48.816l22.781,0l0,-48.816Z" />
            <path d="M225.554,186.22l-17.899,-46.05c8.786,-3.742 14.644,-11.716 14.644,-22.292l0,-24.408c0,-14.645 -11.39,-24.408 -26.035,-24.408l-39.053,0l0,117.158l21.154,0l0,-43.934l7.811,0l17.085,43.934l22.293,0Zm-47.189,-97.632l17.899,0c3.255,0 4.882,1.627 4.882,4.882l0,24.408c0,3.254 -1.627,4.881 -4.882,4.881l-17.899,0l0,-34.171Z" />
            <path d="M296.337,88.588l-38.239,0l0,29.29l31.405,0l0,19.526l-31.405,0l0,29.29l38.239,0l0,19.526l-59.393,0l0,-117.158l59.393,0l0,19.526Z" />
            <path d="M354.103,69.062l21.153,0l0,117.158l-20.34,0l-23.594,-59.555l0,59.555l-21.154,0l0,-117.158l20.34,0l23.595,59.718l0,-59.718Z" />
            <path d="M452.548,88.588l0,-19.526l-59.392,0l0,117.158l21.153,0l0,-48.816l31.405,0l0,-19.526l-31.405,0l0,-29.29l38.239,0Z" />
            <path d="M524.959,88.588l-38.239,0l0,29.29l31.405,0l0,19.526l-31.405,0l0,29.29l38.239,0l0,19.526l-59.393,0l0,-117.158l59.393,0l0,19.526Z" />
            <path d="M559.944,166.694l0,-97.632l-21.154,0l0,117.158l59.393,0l0,-19.526l-38.239,0Z" />
            <path d="M663.271,123.573c9.763,3.417 11.39,10.577 11.39,20.34l0,17.899c0,14.645 -11.39,24.408 -26.035,24.408l-14.645,0c-14.644,0 -26.035,-9.763 -26.035,-24.408l0,-14.645l21.154,0l0,14.645c0,3.255 1.627,4.882 4.881,4.882l14.645,0c3.255,0 4.882,-1.627 4.882,-4.882l0,-14.645c0,-5.695 -0.651,-6.834 -6.509,-8.949l-27.662,-9.764c-9.764,-3.417 -11.391,-10.576 -11.391,-20.34l0,-16.76c0,-13.017 9.275,-22.292 22.293,-22.292l16.76,0c14.645,0 26.035,9.763 26.035,24.408l0,14.644l-21.153,0l0,-14.644c0,-3.255 -1.628,-4.882 -4.882,-4.882l-13.018,0c-3.254,0 -4.881,1.627 -4.881,4.882l0,11.39c0,5.695 0.488,6.834 6.509,8.95l27.662,9.763Z" />
          </g>
          <g style={{ fill: "#b98e62", fillRule: "nonzero" }}>
            <path d="M18.036,28.495c5.23,1.743 7.924,3.724 7.924,8.954l0,11.569c0,5.388 -3.962,9.033 -8.954,9.033l-7.131,0c-4.913,0 -8.875,-3.645 -8.875,-9.033l0,-9.984l2.773,0l0,9.984c0,4.12 1.981,6.101 6.102,6.101l7.131,0c4.12,0 6.18,-1.981 6.18,-6.101l0,-9.984c0,-3.091 -1.109,-5.705 -4.12,-6.735l-10.301,-3.328c-5.229,-1.664 -7.765,-3.725 -7.765,-8.954l0,-8.162c0,-6.338 2.932,-10.855 7.052,-10.855l8.161,0c4.992,0 8.954,3.645 8.954,9.033l0,9.984l-2.773,0l0,-9.984c0,-4.12 -2.06,-6.101 -6.181,-6.101l-6.338,0c-4.121,0 -6.102,2.932 -6.102,8.954l0,5.546c0,3.883 1.11,5.705 4.121,6.735l10.142,3.328Z" />
            <path d="M59.16,20.017l-2.773,0l0,-9.984c0,-4.12 -2.061,-6.101 -6.181,-6.101l-6.339,0c-4.12,0 -6.101,1.981 -6.101,6.101l0,38.985c0,4.12 1.981,6.101 6.101,6.101l6.339,0c4.12,0 6.181,-1.981 6.181,-6.101l0,-9.984l2.773,0l0,9.984c0,5.388 -3.962,9.033 -8.954,9.033l-6.339,0c-4.913,0 -8.874,-3.645 -8.874,-9.033l0,-38.985c0,-5.388 3.961,-9.033 8.874,-9.033l6.339,0c4.992,0 8.954,3.645 8.954,9.033l0,9.984Z" />
            <path d="M89.666,1l2.773,0l0,57.051l-2.773,0l0,-27.575l-18.621,0l0,27.575l-2.773,0l0,-57.051l2.773,0l0,26.624l18.621,0l0,-26.624Z" />
            <path d="M105.83,55.119l0,-54.119l-2.773,0l0,57.051l21.394,0l0,-2.932l-18.621,0Z" />
            <path d="M146.32,1c4.992,0 8.954,3.645 8.954,9.033l0,38.985c0,5.388 -3.962,9.033 -8.954,9.033l-6.339,0c-4.912,0 -8.874,-3.645 -8.874,-9.033l0,-38.985c0,-5.388 3.962,-9.033 8.874,-9.033l6.339,0Zm6.181,9.033c0,-4.12 -2.06,-6.101 -6.181,-6.101l-6.339,0c-4.12,0 -6.101,1.981 -6.101,6.101l0,38.985c0,4.12 1.981,6.101 6.101,6.101l6.339,0c4.121,0 6.181,-1.981 6.181,-6.101l0,-38.985Z" />
            <path d="M181.343,28.495c5.23,1.743 7.924,3.724 7.924,8.954l0,11.569c0,5.388 -3.962,9.033 -8.954,9.033l-7.131,0c-4.913,0 -8.875,-3.645 -8.875,-9.033l0,-9.984l2.774,0l0,9.984c0,4.12 1.98,6.101 6.101,6.101l7.131,0c4.12,0 6.181,-1.981 6.181,-6.101l0,-9.984c0,-3.091 -1.11,-5.705 -4.121,-6.735l-10.301,-3.328c-5.229,-1.664 -7.765,-3.725 -7.765,-8.954l0,-8.162c0,-6.338 2.932,-10.855 7.052,-10.855l8.162,0c4.992,0 8.953,3.645 8.953,9.033l0,9.984l-2.773,0l0,-9.984c0,-4.12 -2.06,-6.101 -6.18,-6.101l-6.339,0c-4.121,0 -6.101,2.932 -6.101,8.954l0,5.546c0,3.883 1.109,5.705 4.12,6.735l10.142,3.328Z" />
            <path d="M215.336,28.495c5.229,1.743 7.923,3.724 7.923,8.954l0,11.569c0,5.388 -3.961,9.033 -8.953,9.033l-7.132,0c-4.912,0 -8.874,-3.645 -8.874,-9.033l0,-9.984l2.773,0l0,9.984c0,4.12 1.981,6.101 6.101,6.101l7.132,0c4.12,0 6.18,-1.981 6.18,-6.101l0,-9.984c0,-3.091 -1.109,-5.705 -4.12,-6.735l-10.301,-3.328c-5.23,-1.664 -7.765,-3.725 -7.765,-8.954l0,-8.162c0,-6.338 2.932,-10.855 7.052,-10.855l8.161,0c4.992,0 8.954,3.645 8.954,9.033l0,9.984l-2.773,0l0,-9.984c0,-4.12 -2.06,-6.101 -6.181,-6.101l-6.339,0c-4.12,0 -6.101,2.932 -6.101,8.954l0,5.546c0,3.883 1.109,5.705 4.12,6.735l10.143,3.328Z" />
          </g>
          <g style={{ fill: "#b98e62", fillRule: "nonzero" }}>
            <path d="M19.214,235.401c5.591,1.864 8.472,3.982 8.472,9.573l0,12.368c0,5.761 -4.236,9.658 -9.573,9.658l-7.625,0c-5.252,0 -9.488,-3.897 -9.488,-9.658l0,-10.674l2.965,0l0,10.674c0,4.406 2.118,6.524 6.523,6.524l7.625,0c4.405,0 6.607,-2.118 6.607,-6.524l0,-10.674c0,-3.304 -1.186,-6.099 -4.405,-7.201l-11.013,-3.558c-5.591,-1.779 -8.302,-3.981 -8.302,-9.573l0,-8.725c0,-6.778 3.134,-11.607 7.54,-11.607l8.725,0c5.338,0 9.573,3.897 9.573,9.658l0,10.674l-2.965,0l0,-10.674c0,-4.405 -2.202,-6.523 -6.608,-6.523l-6.777,0c-4.405,0 -6.523,3.134 -6.523,9.573l0,5.93c0,4.151 1.186,6.1 4.405,7.201l10.844,3.558Z" />
            <path d="M53.863,206.004c5.337,0 9.573,3.897 9.573,9.658l0,51.338l-2.965,0l0,-26.94l-19.909,0l0,26.94l-2.965,0l0,-51.338c0,-5.761 4.236,-9.658 9.488,-9.658l6.778,0Zm6.608,31.007l0,-21.349c0,-4.405 -2.203,-6.523 -6.608,-6.523l-6.778,0c-4.405,0 -6.523,2.118 -6.523,6.523l0,21.349l19.909,0Z" />
            <rect x="74.195" y="206.004" width="2.965" height="60.996" />
            <path d="M111.385,206.004l2.965,0l0,60.996l-2.542,0l-20.331,-51.423l0,51.423l-2.965,0l0,-60.996l2.541,0l20.332,51.423l0,-51.423Z" />
            <path d="M146.457,206.004l-24.144,0l0,3.135l10.59,0l0,57.861l2.965,0l0,-57.861l10.589,0l0,-3.135Z" />
            <rect x="152.133" y="239.467" width="29.651" height="3.05" />
            <path d="M206.521,206.004c5.337,0 9.573,3.897 9.573,9.658l0,51.338l-2.965,0l0,-26.94l-19.908,0l0,26.94l-2.966,0l0,-51.338c0,-5.761 4.236,-9.658 9.489,-9.658l6.777,0Zm6.608,31.007l0,-21.349c0,-4.405 -2.203,-6.523 -6.608,-6.523l-6.777,0c-4.406,0 -6.523,2.118 -6.523,6.523l0,21.349l19.908,0Z" />
            <path d="M249.726,206.004l2.965,0l0,60.996l-2.541,0l-20.332,-51.423l0,51.423l-2.965,0l0,-60.996l2.541,0l20.332,51.423l0,-51.423Z" />
            <path d="M280.309,206.004c5.337,0 9.572,3.897 9.572,9.658l0,41.68c0,5.761 -4.235,9.658 -9.572,9.658l-16.266,0l0,-60.996l16.266,0Zm6.607,9.658c0,-4.405 -2.202,-6.523 -6.607,-6.523l-13.301,0l0,54.727l13.301,0c4.405,0 6.607,-2.118 6.607,-6.524l0,-41.68Z" />
            <path d="M330.969,267l-10.759,-27.533c3.558,-1.27 6.015,-4.574 6.015,-9.064l0,-14.741c0,-5.761 -4.236,-9.658 -9.573,-9.658l-16.266,0l0,60.996l2.965,0l0,-26.94l13.47,0l10.59,26.94l3.558,0Zm-27.618,-57.861l13.301,0c4.405,0 6.608,2.118 6.608,6.523l0,14.741c0,4.405 -2.203,6.608 -6.608,6.608l-13.301,0l0,-27.872Z" />
            <path d="M358.671,191.094l-6.608,11.522l-2.711,0l4.914,-11.522l4.405,0Zm4.066,18.045l-20.925,0l0,25.33l18.384,0l0,3.05l-18.384,0l0,26.347l20.925,0l0,3.134l-23.89,0l0,-60.996l23.89,0l0,3.135Z" />
            <path d="M415.346,234.384c5.083,1.779 5.93,5.507 5.93,10.59l0,9.319c0,7.624 -5.93,12.707 -13.555,12.707l-7.624,0c-7.624,0 -13.555,-5.083 -13.555,-12.707l0,-7.625l11.013,0l0,7.625c0,1.694 0.848,2.541 2.542,2.541l7.624,0c1.695,0 2.542,-0.847 2.542,-2.541l0,-7.625c0,-2.965 -0.339,-3.558 -3.389,-4.659l-14.401,-5.083c-5.083,-1.779 -5.931,-5.507 -5.931,-10.59l0,-8.725c0,-6.778 4.829,-11.607 11.607,-11.607l8.725,0c7.625,0 13.555,5.083 13.555,12.708l0,7.624l-11.013,0l0,-7.624c0,-1.694 -0.847,-2.542 -2.542,-2.542l-6.777,0c-1.694,0 -2.542,0.848 -2.542,2.542l0,5.93c0,2.965 0.255,3.558 3.389,4.659l14.402,5.083Z" />
            <path d="M457.704,206.004l-32.192,0l0,10.166l10.589,0l0,50.83l11.013,0l0,-50.83l10.59,0l0,-10.166Z" />
            <rect x="463.634" y="206.004" width="11.013" height="60.996" />
            <path d="M514.887,216.17l0,-10.166l-30.921,0l0,60.996l11.013,0l0,-25.415l16.35,0l0,-10.166l-16.35,0l0,-15.249l19.908,0Z" />
            <path d="M550.468,206.004l-32.192,0l0,10.166l10.589,0l0,50.83l11.013,0l0,-50.83l10.59,0l0,-10.166Z" />
            <path d="M578.678,206.004l11.013,0l0,48.289c0,7.624 -5.93,12.707 -13.554,12.707l-6.778,0c-7.624,0 -13.554,-5.083 -13.554,-12.707l0,-48.289l11.013,0l0,48.289c0,1.694 0.847,2.541 2.541,2.541l6.778,0c1.694,0 2.541,-0.847 2.541,-2.541l0,-48.289Z" />
            <path d="M621.29,206.004l11.013,0l0,60.996l-10.589,0l-12.284,-31.006l0,31.006l-11.013,0l0,-60.996l10.59,0l12.283,31.091l0,-31.091Z" />
            <path d="M674.661,226.336l0,-7.624c0,-7.625 -5.93,-12.708 -13.554,-12.708l-6.777,0c-7.625,0 -13.555,5.083 -13.555,12.708l0,35.581c0,7.624 5.93,12.707 13.555,12.707l2.965,0c2.88,0 5.167,-1.355 6.353,-3.558l0,3.558l11.013,0l0,-33.039l-18.637,0l0.847,10.166l6.777,0l0,8.471c0,2.965 -1.27,4.236 -4.235,4.236l-5.083,0c-1.695,0 -2.542,-0.847 -2.542,-2.541l0,-35.581c0,-1.694 0.847,-2.542 2.542,-2.542l6.777,0c1.694,0 2.541,0.848 2.541,2.542l0,7.624l11.013,0Z" />
          </g>
        </Svg>
      </Box>
    );
  }
}

export default Logo;
