import React, { Component } from "react";
import { Anchor, Box, Image, Text } from "grommet";

class Avatar extends Component {
  render() {
    const { title, image, phone, email } = this.props;

    return (
      <Box width="medium" round="small" overflow="hidden" background="light-1">
        <Box width="medium" height="small">
          <Image src={image} fit="cover" />
        </Box>
        <Box pad="small" align="center" height="xsmall">
          <Text weight="bold" size="small" textAlign="center">
            {title}
          </Text>
        </Box>
        <Box width="small" align={"start"} gap="small" pad="small">
          <Box direction="column">
            <Text size="xsmall">Telefon</Text>
            <Text size="small">{phone}</Text>
          </Box>
          <Box direction="column">
            <Text size="xsmall">Email</Text>
            <Text size="small">
              <Anchor href={`mailto:${email}`}>{email}</Anchor>
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Avatar;
