import React, { Component } from "react";
import { Box, Heading } from "grommet";
import RoutedAnchor from "./RoutedAnchor";
import { getGradient } from "../util";

class Footer extends Component {
  render() {
    const bg = getGradient("#333333");

    return (
      <Box margin="none" pad="none" direction="column" background={bg}>
        <Box pad="medium" align="center">
          <Box direction="row" gap="large">
            <Box gap="small" size="small" border="right">
              <Box pad={{ horizontal: "large" }} direction={"column"}>
                <Heading level={4} strong={true}>
                  Informationen
                </Heading>
                <RoutedAnchor href={"/information"}>
                  Schloss Ehrenfels
                </RoutedAnchor>
              </Box>
            </Box>
            <Box gap="small" size="small">
              <Box pad={{ horizontal: "large" }} direction={"column"}>
                <Heading level={4} strong={true}>
                  Kontakt
                </Heading>
                <RoutedAnchor href={"/directions"}>Anfahrt</RoutedAnchor>
                <RoutedAnchor href={"/contact"}>Kontaktformular</RoutedAnchor>
                <RoutedAnchor href={"/legal"}>Impressum</RoutedAnchor>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          pad={{ vertical: "small", horizontal: "medium" }}
          gap="medium"
          direction="row"
        >
          <span>
            &copy; Copyright 2020, Schloss Ehrenfels Saint-André Stiftung. All
            rights reserved.
          </span>
          <RoutedAnchor href="/terms">Nutzungsbedingungen</RoutedAnchor>
          <RoutedAnchor href="/privacy">Datenschutz</RoutedAnchor>
        </Box>
      </Box>
    );
  }
}

export default Footer;
