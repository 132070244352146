import React, { Component } from "react";
import { Paragraph } from "grommet";
import Section from "../components/Section";
import Page from "../components/Page";
import SectionImage from "../components/SectionImage";

class Information extends Component {
  render() {
    return (
      <Page title="Informationen zum Schloss Ehrenfels">
        <Section title="Die Ruine Alt-Ehrenfels" background="white">
          <Paragraph fill>
            Die Ruine Alt-Ehrenfels liegt abgeschieden wild auf steilem Fels vom
            Wald umgeben bei der Einmündung des Schweiftals in das Glastal.
          </Paragraph>
          <SectionImage
            src={"/img/ruin/view1.jpg"}
            alt={"Die Ruine Alt-Ehrenfels"}
            caption={"Die Ruine Alt-Ehrenfels"}
            source={"Heinz Thumm, Alle Rechte vorbehalten"}
          />
          <Paragraph fill>
            Die Burg Ehrenfels, vermutlich zwischen 1220 und 1290 erbaut, war
            der Sitz der Herren von Ehrenfels. Die Familie tritt erstmalig im
            Jahr 1293 durch Heinrich von Ehrenfels als Mönch im Kloster
            Zwiefalten in das Licht der Geschichte, (lt. Urkunde aus Klst.
            Mariaberg). Anselm von Ehrenfels war im Jahr 1366 – 1383 Abt in
            Zwiefalten, er starb als letzter seines Geschlechts (1383) lt.
            Urkunde: Anselmus de Erenvels. 1334 sitzt ein Ritter Swigger von
            Gundelfingen auf „seinem Schlosse Ehrenfels“ (residens in arce sua).
          </Paragraph>
          <Paragraph fill>
            1403 verkauft ein Swigger von Gundelfingen und Ehrenfels einen Hof
            zu Hochberg. Von 1408 an findet man die Kayben von Hohenstein im
            Besitze von Ehrenfels, welches Simon von Kaib 1469 an Graf Ulrich
            von Württemberg und dieser dann im Jahre 1474 an das Kloster
            Zwiefalten verkauft. Im Jahr 1516 ließ Abt Sebastian Müller
            (Molitor) die Burg zerstören, weil sie ein Schlupfwinkel für Räuber
            geworden war, die die „Ulmer Landstraße“ unsicher machten.
          </Paragraph>
          <Paragraph fill>
            Hier beginnt das Glastal mit dem Hasenbach, der bei einem Felsentor
            (Karstquelle) aus einem Becken herausdringt. Das Tal hat seinen
            Namen von einer Glashütte, welche in früherer Zeit dort stand, auf
            dem Wege sieht man heute noch Quarzsand schimmern. Zahlreiche
            Stellen und eingefallene Schächte weisen auf den Abbau von Eisenerz
            hin, das in der Umgebung von Ehrenfels und Wimsen als „Bohnerz“
            gefunden wurde. Viele Spuren von Holzkohlenmeilern finden sich in
            diesem Raum.
          </Paragraph>
          <SectionImage
            src={"/img/ruin/thumb.jpg"}
            alt={"Der Eingang zur Ruine Alt-Ehrenfels"}
            caption={"Der Eingang zur Ruine Alt-Ehrenfels"}
            source={"Heinz Thumm, Alle Rechte vorbehalten"}
          />
          <Paragraph fill>
            Am Bach direkt unterhalb des Schlosses Ehrenfels stand ehemals eine
            Mahlmühle. Später wurde sie zur Wasserversorgung des Hofgutes
            genutzt. Nach der Erstellung eines kleinen Pumphäuschens mit Turbine
            zerfiel der Barockbau. Heute ist nur noch eine Ruine mit Resten
            eines großen Mühlrades vorhanden.
          </Paragraph>
        </Section>
        <Section title="Neu Ehrenfels" background="light-2">
          <Paragraph fill>
            Auf einer Talterrasse (588m Meereshöhe) erbaute sich 1735 – 1740 Abt
            Augustin Stegmüller vom Kloster Zwiefalten eine
            Sommerresidenz.Vorher befand sich hier ein Meierhof, der aus zwei
            Bauerngehöften entstanden war.
          </Paragraph>
          <SectionImage
            size={"large"}
            alt={"Westansicht Schloss Ehrenfels"}
            caption={"Westansicht Schloss Ehrenfels"}
            source={"Regine Flitsch"}
            src={"/img/ehrenfels/west.jpg"}
          />
          <Paragraph fill>
            Es handelt sich um einen langgestreckten Barockbau, der nur durch
            die in der Mittelachse liegende vorspringende Kapelle gegliedert
            wird und einen großen hallenmäßig dreischiffigen Keller (66m) mit
            Kreuznahtgewölben besitzt. Die Wirtschaftsgebäude bilden mit ihm
            eine vierflügelige Anlage, „beinahe in der Art eines Ehrenhofes“.
            Das Marmorwappen über dem Nordportal zeigt ein sogenanntes
            sprechendes Wappen. Unter einem Relief der Gottesmutter sieht man
            einen Steg über einem Mühlrad, das Wappen des Abtes Stegmüller (1725
            – 1744).
          </Paragraph>
          <Paragraph fill>
            Nach der Säkularisation 1803 schenkte Kurfürst Friedrich von
            Württemberg den Besitz (Ehrenfels, Maßhalderbuch und Wimsen) dem
            damaligen Staatsminister Philipp Christian von Normann, einem
            pommerischen Edelmann und einstigen Karlschüler, der in der
            Napoleonischen Zeit einer der bedeutesten württembergischen
            Staatsmänner war, als Belohnung „für diejenigen wichtigen Dienste,
            welche derselbe seit vielen Jahren und insbesondere bei den letzten
            Friedensverhandlungen und als Subdelegatus bei der außerordentlichen
            Reichs-Deputation geleistet hat“; seit dieser Zeit führt die Familie
            den Namen Normann-Ehrenfels. Dass der Kurfürst seinem Minister
            solche Besitzungen schenkte, die in einer bisher ziemlich unwegsamen
            Gegend lagen, war für diese ein Vorteil, weil fortan im ganzen
            Fürstentum Zwiefalten für den Wegebau mehr geschah, als zu Zeiten
            des Klosters geschehen konnte. Für den Minister und seine Nachfahren
            war dagegen das Geschenk keine reine Freude.
          </Paragraph>
          <SectionImage
            size={"large"}
            alt={"Schlossgarten"}
            caption={"Schlossgarten"}
            source={"Regine Flitsch"}
            src={"/img/ehrenfels/garden.jpg"}
          />
          <Paragraph fill>
            Der karge und felsige Boden auf der schwäbischen Alb mit ihrem rauen
            Klima brachte nur geringe Erträgnisse. Die Erhaltung der alten
            Klostergebäude wurde dadurch zu einer schweren Last.
          </Paragraph>
        </Section>
        <Section title="Der Keller" background="white">
          <Paragraph fill>
            Zwiefalten liegt in einem Tuffsteingebiet am Zusammenfluss zweier
            Achen, wovon die eine in der Wimsener-Höhle, die andere im Dobeltal
            entspringt. Daher der alte Name{" "}
            <abbr title="mittelhochdeutsch: zwiefältig, aha heißt Wasser, Bach">
              Zwiefaltaha
            </abbr>
            . Das Wort geht auf die gleiche Urform zurück, wie das lateinischa
            aqua und lebt im heutigen Ach fort.
          </Paragraph>
          <Paragraph fill>
            Kloster und Kirche befinden sich in einem Sumpfgelände. Der Chor der
            Kirche steht auf unendlich vielen Eichenpfählen. Die wenigen
            Kellerräume des Klosters waren nach starken Regenfällen mit Wasser
            angefüllt. Damit stand der Entschluss fest, einen großen Keller in
            trockenem Gelände zu bauen, um Messwein, Trinkwein und sonstige
            Vorräte zu lagern. Gehörten doch zu Zwiefalten auch Weinberge in der
            Bodenseegegend. Der Bau eines Sommerhauses auf einen so gut
            fundierten Keller bot sich an.
          </Paragraph>
          <SectionImage
            alt={"Kellergang"}
            caption={"Kellergang"}
            source={"Heinz Thumm, Alle Rechte vorbehalten"}
            src={"/img/cellar/hall.jpg"}
          />
          <Paragraph fill>
            Dieser ist 66m lang, dreischiffig mit Kreuznahtgewölbe. Aus
            Flözdolomitt aus dem Gauinger Steinbruch sind die schön bearbeiteten
            riesigen Säulen. Ein trockener Lehmboden, eine ideale Belüftung und
            eine offengeführte Regenwasserableitung des Hofes sorgen für die
            richtige Luftfeuchtigkeit. Die Temperatur im Keller ist weitgehend
            konstant, schwankt lediglich zwischen 6 und 10° während des Jahres,
            da die vielen Fenster von Frühjahr bis Spätherbst offen sind. Wie
            man beobachten kann, sind unterhalb mehrerer Fenster gemauerte
            Schächte bis weit in den Keller hinuntergeführt, es entsteht eine
            ideale Lüftung, da Kälte und Wärme zirkulieren können. Seitlich ist
            ein Rübenkeller, die Rüben wurden oben am Eingang durch eine Öffnung
            im Boden hinuntergeworfen.
          </Paragraph>
        </Section>
        <Section title="Die Halle">
          <SectionImage
            alt={"Die Halle"}
            caption={"Die Halle"}
            source={"Reinhold Flitsch"}
            src={"/img/chapel/chapel.jpg"}
          />
          <Paragraph fill>
            Die Halle war ehemals die Kapelle des Abtes. Die Türe mit Treppe in
            den Garten und die ganze Anlage des Gartens wurde erst 1875 von Graf
            Wilhelm Normann mit Hilfe eines Gartenbaumeisters H. Grube aus
            Krauchenwies angelegt. Ein hübsch aquarellierter Plan ist im Archiv
            vorhanden.
          </Paragraph>
          <SectionImage
            alt={"Türe in den Garten"}
            caption={"Türe in den Garten"}
            source={"Regine Flitsch"}
            src={"/img/chapel/exit.jpg"}
          />
          <Paragraph fill>
            An Decke und Empore ist bescheidener Stuck angebracht. Der Boden
            zeigt das Rosenmuster aus Solnhofer Schiefer. Die kleine
            Steinpyramide in einer Ecke der Halle wurde zur Erinnerung an den
            unglücklichen Sohn des Ministers, der im griechischen Freiheitskrieg
            1822 in Messalonghi ums Leben kam, errichtet.
          </Paragraph>
          <SectionImage
            alt={"Die Empore"}
            caption={"Die Empore"}
            source={"Regine Flitsch"}
            src={"/img/chapel/gallery.jpg"}
          />
          <Paragraph fill>
            Vor der Übergabe an den Minister wurde die Kapelle vollständig
            ausgeräumt, ebenso wurde die gesamte Einrichtung des Hauses
            entfernt.
          </Paragraph>
        </Section>
        <Section title="Die Gänge" background="light-2">
          <Paragraph fill>
            Die langen und breiten Gänge, wie in Klosterbauten üblich, sind 64m
            lang und 4m breit. Im Parterre um 8m kürzer, da ein zusätzlicher
            Raum gewonnen werden sollte. Das Grundmaß aller Zimmer ist 8x8m,
            wobei einige aber wieder im entsprechenden Verhältnis unterteilt
            sind. Der verschiedenartige rötliche Ton, der mal stärker, mal
            schwächer gebrannten Platten, gibt dieser langen Flucht Leben.
            Dieser Plattenboden kann nur mit festem Besen gekehrt und mit viel
            klarem Wasser aufgewischt werden. Der Vorteil dieser Behandlung ist,
            dass das Wasser die Platten mit ihrer Sandunterlage verbindet. Auch
            die Fenster mit den Butzenscheiben stammen noch aus der Bauzeit.
          </Paragraph>
          <Paragraph fill>
            Es befinden sich hier auch einige interessante Gemälde: das Bildnis
            des Abts Anselm (1366/83) eines geb. Ritter von Ehrenfels verewigt
            den letzten dieses Geschlechts (Johann Jüngling, 1615). Die
            Darstellung des Wunders von Monte Cassino. Der heilige Benedikt
            heilt einen Mönch, der beim Bau des Klosters verunglückte.
          </Paragraph>
          <Paragraph fill>
            Die übrigen Kirchenbilder stammen aus der Lorettokapelle; diese
            wurde von dem Grafen Normann 1834 vom Staat gekauft, da sie seit
            1803 als Scheuer diente. 1897 kaufte der Staat sie wieder zurück,
            ebenso die Hofgebäude von dem Bauern Franz Spindler für die
            Irrenanstalt Zwiefalten. Es wurde in Loretto anschließend eine
            Lungenheilstätte für die Anstaltinsassen gegründet.
          </Paragraph>
          <SectionImage
            alt={"Die Gänge"}
            caption={"Die Gänge"}
            source={"Regine Flitsch"}
            src={"/img/halls/hall.jpg"}
          />
        </Section>
        <Section title="Das Esszimmer">
          <Paragraph fill>
            Bestimmt waren die Äbte des Klosters Zwiefalten nicht allzu häufig
            hier, Ehrenfels war ja nur einer von mehreren Sommersitzen der
            Reichsabtei. Wenn es aber der Fall war, standen ihnen die Zimmer im
            oberen Stock zur Verfügung. Das Erdgeschoss waren einfach gekalkte
            Wirtschaftsräume.
          </Paragraph>
          <Paragraph fill>
            Aus diesem Grunde entschloss sich Graf Wilhelm Normann nach der
            Geburt seines ersten Sohnes, zwei dieser kargen Räume wohnlicher zu
            gestalten.
          </Paragraph>
          <SectionImage
            alt={"Holztäfelung"}
            caption={"Holztäfelung"}
            source={"Regine Flitsch"}
            src={"/img/dining/wood.jpg"}
          />
          <Paragraph fill>
            Drei Vettern, Edward Hornstein, Ernst Freyberg und Wilhelm Normann
            sägten, schnitzten und malten viele, viele Wochen in fröhlicher
            Zusammenarbeit. Sie brachten eine Holztäfelung an, deren Abschluss
            ein geschnitzter und eingebrannter Fries mit originellen Jagdszenen
            bildete. Selbst den Balken an der Decke gaben sie als Abschluss
            fröhliche Farben. Auch Schränke, Schränkchen, Kasten, viele Stühle
            und Hocker waren vor ihrer Kunstbegeisterung nicht sicher, so
            entstanden im Barockschloss Ehrenfels zwei „gotische“ Räume. Schloss
            Voipers in Südtirol sowie das Museum in Innsbruck, das viele
            ausgemalte Räume mit Schnitzarbeiten aus Tiroler Schlössern birgt,
            dienten zum Vorbild. Die Beschläge der Türen und Kasten sowie der
            Kronleuchter fertigte nach Zeichnungen von Edward Hornstein
            Schlossermeister Wörz aus Hayingen.
          </Paragraph>
          <SectionImage
            alt={"Wappenmalereien"}
            caption={"Wappenmalereien"}
            source={"Regine Flitsch"}
            src={"/img/dining/wall.jpg"}
          />

          <Paragraph fill>
            Die „Wappenmalereien“ in leuchtenden Farben (x-mal mit klarem Wasser
            gewaschen), in schwungvoller gotischer Schrift die Lebensdaten, sind
            die Ahnen des Hausherren. Im Unterteil des großen Ofens ist das
            Wappen des Abtes Ulrich von Rothhäusler von 1695 angebracht. Die
            Platte ist also älter als das Ehrenfelser Schloss.
          </Paragraph>
        </Section>
        <Section title="Das Winterwohnzimmer" background="white">
          <Paragraph fill>
            Ebenfalls getäfert und geschnitzt ist das Winterwohnzimmer. Der Name
            erklärt sich daraus, dass die Familie Normann im Winter vom oberen
            Stock in das Erdgeschoss zog, da die großen Räume oben mit ihren
            hohen Fenstern nur schwer heizbar waren.
          </Paragraph>
          <SectionImage
            alt={"Wappen"}
            caption={"Wappen"}
            source={"Regine Flitsch"}
            src={"/img/livingroom/armorial.jpg"}
          />
          <Paragraph fill>
            Die geschnitzten bunten Wappen an den Deckenbalken sind die der
            Frauen, die in die Familie Normann einheirateten und zwar auf der
            Gartenseite die Wappen des Ministers. Auf der Gangseite beginnt die
            Wappenreihe mit der Frau des Ministers (von Harling).
          </Paragraph>
          <SectionImage
            alt={"Wandschirm"}
            caption={"Wandschirm"}
            source={"Regine Flitsch"}
            src={"/img/livingroom/screen.jpg"}
          />
          <Paragraph fill>
            Die Szenen auf dem den Ofen umgebenden Wandschirm zeigen Gruppen aus
            dem Festzug des Gewerbes der Stadt Wien anlässlich der Silbernen
            Hochzeit Kaiser Franz Josefs und der Kaiserin Elisabeth nach dem
            Original von Mahan.
          </Paragraph>
          <SectionImage
            alt={"Das Winterwohnzimmer"}
            caption={"Das Winterwohnzimmer"}
            source={"Regine Flitsch"}
            src={"/img/livingroom/thumb.jpg"}
          />
        </Section>
      </Page>
    );
  }
}

export default Information;
