import React, { Component } from "react";
import { Anchor } from "grommet";
import { withRouter } from "react-router-dom";

class RoutedAnchor extends Component {
  onClick = e => {
    const { href, history, onClick } = this.props;
    e.preventDefault();
    if (history && href) {
      history.push(href);
    }
    if (onClick) {
      onClick();
    }
  };

  render() {
    return <Anchor {...this.props} onClick={this.onClick} />;
  }
}

export default withRouter(RoutedAnchor);
