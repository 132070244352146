import React, { Component } from "react";
import { Box, Heading, Text } from "grommet";
import { getGradient } from "../util";

class Section extends Component {
  render() {
    const {
      background,
      children,
      title,
      striped,
      date,
      author,
      ...props
    } = this.props;

    const meta =
      date && author ? (
        <Text size="xsmall" color="dark-4">
          {date.toLocaleString()}, {author}
        </Text>
      ) : null;

    const header = title ? (
      <Box direction="column">
        {meta}
        <Heading level={2} id={title} margin={{ top: "none" }}>
          {title}
        </Heading>
      </Box>
    ) : null;

    const color = striped ? getGradient(background) : background;

    return (
      <Box
        as="article"
        direction="column"
        align="center"
        background={color}
        height={{ min: "medium" }}
        {...props}
      >
        <Box
          width="large"
          pad={{ top: "medium", bottom: "xlarge", horizontal: "medium" }}
          direction="column"
        >
          {header}
          {children}
        </Box>
      </Box>
    );
  }
}

export default Section;
