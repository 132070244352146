import React, { Component } from "react";
import { InfiniteScroll, Markdown, Paragraph } from "grommet";
import Section from "../components/Section";
import SectionImage from "../components/SectionImage";
import Page from "../components/Page";

const components = {
  p: {
    component: Paragraph,
    props: {
      fill: true
    }
  }
};

const parseImage = function(line, key) {
  // parse image
  const re = new RegExp(/!\[(.+)\]\((.*)[ ]"(.*)"\)/, "i");

  if (!re.test(line)) {
    return null;
  }

  const results = re.exec(line);
  const title = results[1];
  const name = results[2];
  const author = results[3];

  return <SectionImage caption={title} source={author} src={name} key={key} />;
};

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      loading: false
    };
  }

  componentDidMount() {
    this._onMore();
  }

  _onReceivedData = data => {
    if (!data.ok) {
      return Promise.reject("Request was unsuccessful");
    }

    return data.json().then(body => {
      const { articles } = body;

      console.log("loaded");
      // merge list
      this.setState({
        articles: articles,
        loading: false
      });
    });
  };

  _onMore = () => {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true
    });

    // send the request
    const that = this;
    fetch("/articles.php", {
      method: "GET"
    })
      .then(this._onReceivedData)
      .catch(err => {
        console.error("Failed to load articles", err);
        that.setState({
          loading: false
        });
      });
  };

  _onRenderArticle = (item, index) => {
    const { author, created, title, content } = item;

    // process content
    const items = [];
    let cache = [];
    content.forEach(line => {
      // found an image
      if (line.indexOf("![") === 0) {
        // if the cache is not empty, we need to push it to the results first
        if (cache.length > 0) {
          items.push(
            <Markdown key={items.length} components={components}>
              {cache.join("\n")}
            </Markdown>
          );
          cache = [];
        }

        items.push(parseImage(line, items.length));
        return;
      }

      cache.push(line);
    });

    if (cache.length > 0) {
      items.push(
        <Markdown key={items.length} components={components}>
          {cache.join("\n")}
        </Markdown>
      );
    }

    return (
      <Section
        key={index}
        title={title}
        background="white"
        author={author}
        date={new Date(created)}
      >
        {items}
      </Section>
    );
  };

  render() {
    const { articles, loading } = this.state;

    if (articles.length === 0 && loading) {
      return (
        <Page title="Aktuelles">
          <Section>Artikel werden geladen...</Section>
        </Page>
      );
    }

    if (articles.length === 0) {
      return (
        <Page title="Aktuelles">
          <Section>Im Moment sind keine Blog Eintr&auml;ge vorhanden.</Section>
        </Page>
      );
    }

    return (
      <Page title="Aktuelles">
        <InfiniteScroll items={articles} step={2} onMore={this._onMore}>
          {this._onRenderArticle}
        </InfiniteScroll>
      </Page>
    );
  }
}

export default Information;
