import React, { Component } from "react";
import { Box, Text, Anchor } from "grommet";
import Section from "../components/Section";
import Page from "../components/Page";
import Avatar from "../components/Avatar";
import RoutedAnchor from "../components/RoutedAnchor";

class Legal extends Component {
  render() {
    return (
      <Page title="Impressum">
        <Section title="Betreiber" background="white">
          <Box>
            <address>
              <Text weight="bold">Schloss Ehrenfels Saint-André Stiftung</Text>
              <br />
              <Text>Ehrenfels 1,</Text>
              <br />
              <Text>72534 Hayingen</Text>
            </address>
          </Box>
          <Box pad={{ vertical: "medium" }}>
            <Text size="small">
              Rechtsfähige Stiftung gem. §80 BGB i.V.m §5 StiftG,
            </Text>
            <Text size="small">
              Regierungspräsidium Tübingen Nr. 24-1/0563-104RT
            </Text>
          </Box>
          <Box pad="small">
            <RoutedAnchor href="/terms">Nutzungsbedinungen</RoutedAnchor>
            <RoutedAnchor href="/privacy">Datenschutzerklärung</RoutedAnchor>
          </Box>
        </Section>
        <Section title="Vorstand">
          <Box direction="row" gap="medium" responsive>
            <Avatar
              image="/img/nicoletta.jpg"
              title="Nicoletta Freifrau von Saint-André"
              phone="+49 (0) 7071 792422"
              email="n.st-a@gmx.de"
            />
            <Avatar
              image="/img/johannes.jpg"
              title="Johannes Freiherr von Ritter zu Groenesteyn"
              phone="+49 (0) 171 815 0229"
              email="johannesv.ritter@web.de"
            />
            <Avatar
              image="/img/reinhold.jpg"
              title="Reinhold Flitsch"
              phone="+49 (0) 7381 8659"
              email="reinhold.flitsch@gmail.com"
            />
          </Box>
        </Section>
        <Section
          title="Konzeption, Gestaltung und Programmierung"
          background="white"
        >
          <Box direction="column">
            <Text weight="bold">Tobias Flitsch</Text>
            <Text size="small">
              <Anchor href="https://tflitsch.com">tflitsch.com</Anchor>
            </Text>
          </Box>
          <Box pad={{ vertical: "medium" }}>
            <Text size="small">
              Diese Website wurde mit{" "}
              <Anchor href="https://grommet.io">Grommet</Anchor> erstellt.
            </Text>
          </Box>
        </Section>
      </Page>
    );
  }
}

export default Legal;
