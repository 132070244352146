import React, { Component } from "react";
import { Anchor, Box, Heading, Paragraph } from "grommet";
import Section from "../components/Section";
import RoutedAnchor from "../components/RoutedAnchor";
import Page from "../components/Page";
import Logo from "../components/Logo";

class Home extends Component {
  render() {
    return (
      <Page title="Schloss Ehrenfels Saint-André Stiftung" disable>
        <Section background="url(/img/img_home_2_1080.jpg)">
          <Logo width="large" height="medium" />

          <Box
            background={{ color: "neutral-1", opacity: "strong" }}
            pad="medium"
          >
            <Paragraph size="xlarge">
              Zur Erhaltung von Kunst, Kultur, Historische Geschichte und
              Gebäude, Natur und Landschaft.
            </Paragraph>
          </Box>
        </Section>
        <Section title="Die Stiftung" background="white">
          <Box>
            <Paragraph fill>
              Die Schloss Ehrenfels Saint-André Stiftung wurde im Jahr 2016 zum
              Zweck der Erhaltung von Kunst, Kultur und historische Geschichte,
              Erhaltung von historischen Gebäuden, Natur und Landschaft
              gegründet.
            </Paragraph>
            <Paragraph fill>
              Die Stiftung verfolgt ausschließlich und unmittelbar gemeinnützige
              Zwecke, insbesondere die Förderung von künstlerischen und
              kultureller Aktivitäten mit Konzerten und anderen Veranstaltungen
              im Denkmal geschützten Schloss Ehrenfels. Auch die Erhaltung und
              Offenhaltung des Schlossmuseums, der musealen Räume des Schlosses
              sowie die unter Denkmal stehende Mühle und den darin befindlichen
              Museen gehört zu den Aktivitäten der Stiftung.
            </Paragraph>
          </Box>
          <Box>
            <Paragraph fill>
              Einnahmen durch Vermietung an Gastronomie, Forstwirtschaft und
              div. Vermietung bzw. Pacht dienen ausschließlich für die Erhaltung
              des Schlosses und der dazugehörigen Kulturgüter und Ländereien.
            </Paragraph>
            <Paragraph fill>
              Dazu kommt die Erhaltung, Pflege und Bewirtschaftung der im
              Biosphärengebiet (UNESCO-Erbe) gelegenen Wälder, die Pflege und
              Erhaltung der natürlichen im Landschaftsschutzgebiet gelegenen
              Streuwiesen, Wachholderheiden und die zwei Bäche sowie die
              Friedrichshöhle, die tiefste befahrbare Höhle Deutschlands.
            </Paragraph>
          </Box>
        </Section>
        <Section
          title="Leisten Sie einen Beitrag!"
          background="neutral-2"
          striped
        >
          <Paragraph fill>
            Der Unterhalt historischer Anlagen ist{" "}
            <strong>außerordenlich aufwendig und kostspielig</strong>. Die
            Einnahmen aus genannten Vermietung bzw. Pacht decken nur einen
            Bruchteil der Erhaltungskosten ab. Ihre Spende trägt dazu bei,
            historische Bauten, einzigartige Kunstwerke und herrliche
            Landschaften für künftige Generationen zu bewahren.
          </Paragraph>
          <RoutedAnchor href={"/contact#kontaktformular"} primary={true}>
            Jetzt Kontakt aufnehmen
          </RoutedAnchor>
        </Section>
        <Section background="light-2" fill>
          <Box direction="row" gap="medium">
            <Box>
              <Heading level={3}>Gastronomie</Heading>
              <Paragraph>
                Das Schloss Ehrenfels und die Wimsener Mühle werden
                gastronomisch durch die Bio-Gastronomie der Familie Tress
                betrieben. Die Einrichtungen stehen für Hochzeiten, Tagungen und
                sonstige Feiern zur Verfügung.
              </Paragraph>
              <Paragraph>
                Weitere Informationen über Buchung, sowie Tischreservierungen
                finden Sie auf der Webseite{" "}
                <Anchor href={"http://www.tress-gastronomie.de"}>
                  tress-gastronomie.de
                </Anchor>
              </Paragraph>
            </Box>
            <Box>
              <Heading level={3}>Freizeitgestaltung</Heading>
              <Paragraph>
                Die Gegend um das Schloss Ehrenfels bietet zahlreiche
                Möglichkeiten zur Freizeitgestaltung für Wanderer, Radfahrer,
                Entdecker, und Naturfreunde. Erleben Sie die Vielfalt der Natur,
                und genießen Sie ihre Wunder und Abenteuer.
              </Paragraph>
              <Paragraph>
                Weitere Informationen über Sehenswertes im Raum Hayingen finden
                Sie auf der Webseite{" "}
                <Anchor href={"http://www.naturerlebnis-hayingen.de"}>
                  naturerlebnis-hayingen.de
                </Anchor>
              </Paragraph>
            </Box>
          </Box>
        </Section>
      </Page>
    );
  }
}

export default Home;
