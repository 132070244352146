/**
 * Copyright (C) 2017 Tobias Flitsch. All rights reserved.
 * Version 0.1
 */

import React, { Component } from "react";
import { Box, Image, Text } from "grommet";

class AppImage extends Component {
  render() {
    const { caption, source, src } = this.props;

    let description;
    if (caption && caption.length > 0) {
      let subtitle;
      if (source && source.length > 0) {
        subtitle = <span>Foto: {source}</span>;
      }

      description = (
        <Box pad="small">
          <Text size="small" align="center">
            <strong>{caption}</strong>
            <br />
            {subtitle}
          </Text>
        </Box>
      );
    }

    return (
      <Box
        gap="small"
        align="start"
        round="small"
        direction="column"
        width="large"
        overflow="hidden"
        background="light-1"
      >
        <Image src={src} fit="contain" fill />
        {description}
      </Box>
    );
  }
}

export default AppImage;
