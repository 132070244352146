import React, { Component } from "react";
import { Box, Heading } from "grommet";
import { withRouter } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import { getGradient } from "../util";

class Page extends Component {
  render() {
    const { title, disable } = this.props;

    // update page title
    let mainTitle;
    if (title && title.length > 0) {
      document.title = `${title} | Schloss Ehrenfels`;

      // setup main title
      if (!disable) {
        mainTitle = (
          <Box as="article" direction="column" align="center">
            <Box width="large" pad="medium" direction="column">
              <Heading level={1}>{title}</Heading>
            </Box>
          </Box>
        );
      }
    } else {
      document.title = "Schloss Ehrenfels";
    }

    return (
      <ScrollToTop>
        <Box background={getGradient("#ffffff")}>
          <Header />
          {mainTitle}
          {this.props.children}
          <Footer />
        </Box>
      </ScrollToTop>
    );
  }
}

export default withRouter(Page);
